import Keycloak from "keycloak-js";

const location = window.location;

const realm = location.hostname.includes('localhost') ? 'digpasstest' : "aktobe-sic"

const keycloak = new Keycloak({
    url: "https://vpn.csi.kz/auth",
    realm: realm,
    clientId: "aktobe-ea",
});

export default keycloak;